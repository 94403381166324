import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Card, CardContent, CardHeader, Button, CircularProgress } from "@cuda-networks/bds-core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, { PageTitles } from "../../../Utilities/useDocumentTitle";
import { IAppState } from "../../../store/store";
import NavigateTo from "../../NavigateTo";
import { getAccountIdFromUrl } from "../../../Utilities/UrlParser";
import ExportUsageDialog from "../../Products/ExportUsageDialog";
import ExportUserBillingListDialog from "../../Products/ExportUserBillingListDialog";
import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";
import { setSnackBarMessage } from "../../../actions/generalActions";
import { addReportScheduleAction, cancelReportScheduleAction, deleteReportScheduleAction, exportCsvReportAction, fetchReportSchedules, runReportScheduleAction, updateReportScheduleAction } from "../../../actions/productActions";
import ActionMessageType from "../../../models/ActionMessageType";
import IAccount from "../../../models/IAccount";
import ReportSchedulesTable from "./ReportSchedulesTable";
import IReportSchedule from "../../../models/ReportSchedule/Types/IReportSchedule";
import { ReportScheduleType } from "../../../models/ReportSchedule/ReportScheduleType";
import IReportScheduleForDisplay from "../../../models/ReportSchedule/IReportScheduleForDisplay";
import { getFrequencyForDisplay } from "../../../models/ReportScheduleFrequencyConverter";
import AddScheduleDialog from "./AddScheduleDialog";
import { IReportScheduleActionsProps } from "./ReportScheduleActions";
import { ReportScheduleStatus } from "../../../models/ReportSchedule/ReportScheduleStatus";
import DeleteScheduleDialog from "./DeleteScheduleDialog";

const ReportSchedules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountId = getAccountIdFromUrl();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const [showGenerateCsvReport, setShowGenerateCsvReport] = useState(false);
  const [showExportUserBillingList, setShowExportUserBillingList] = useState(false);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [reportSchedules, setReportSchedules] = useState<IReportScheduleForDisplay[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [showDeleteScheduleConfirmation, setShowDeleteScheduleConfirmation] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState<number>(0);

  useDocumentTitle(selectedAccount, PageTitles.ReportSchedules);

  const navigateTo = `/accounts/${accountId}/products-services`;
  const handleBack = (event: React.SyntheticEvent) => {
    navigate(`/accounts/${accountId}/products-services`);
  };

  const handleCancelExportUsage = () => {
    setShowGenerateCsvReport(false);
  };

  const exportCsv = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(exportCsvReportAction(mspAccountLoggedIn, selectedAccount));
      resolve(result);
    });

  const handleOnExportUsage = () => {
    setActionInProgress(true);
    if (selectedAccount) {
      exportCsv(mspAccountLoggedIn, selectedAccount).then(result => {
        if (result) {
          setShowGenerateCsvReport(false);
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.ExportCsvReport].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        }
        setActionInProgress(false);
      });
    }
  };

  const onExportUserBillingListSubmit = () => {
    setShowExportUserBillingList(false);
  };

  const onExportUserBillingListCancel = () => {
    setShowExportUserBillingList(false);
  };

  const createReportScheduleForDisplay = (reportSchedule: IReportSchedule) => {
    const newScheduleForDisplay: IReportScheduleForDisplay = {
      id: reportSchedule.id ?? 0,
      name: reportSchedule.name,
      type: reportSchedule.type === ReportScheduleType.UserBillingList ? "User Billing List" : "Usage Data",
      frequencyType: getFrequencyForDisplay(reportSchedule),
      numberOfAccounts: reportSchedule.numberOfAccounts ?? 0,
      reportingEnabled: reportSchedule.reportingEnabled ?? false,
      status: reportSchedule.status ?? ReportScheduleStatus.None,
    };
    return newScheduleForDisplay;
  };

  const addReportSchedule = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(addReportScheduleAction(mspAccountLoggedIn, selectedAccount, reportSchedule));
      resolve(result);
    });

  const updateReportSchedule = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(updateReportScheduleAction(mspAccountLoggedIn, selectedAccount, reportSchedule));
      resolve(result);
    });

  const deleteReportSchedule = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount, id: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(deleteReportScheduleAction(mspAccountLoggedIn, selectedAccount, id));
      resolve(result);
    });

  const runReportSchedule = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(runReportScheduleAction(mspAccountLoggedIn, selectedAccount, reportSchedule));
      resolve(result);
    });

  const cancelReportSchedule = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(cancelReportScheduleAction(mspAccountLoggedIn, selectedAccount, reportSchedule));
      resolve(result);
    });

  const onAddReportScheduleSucceeded = (reportSchedule: IReportSchedule) => {
    dispatch(
      setSnackBarMessage({
        message: ActionMessages[ActionTypes.AddReportSchedule].infoMessage,
        type: ActionMessageType.Info,
      }),
    );
    const scheduleForDisplay = createReportScheduleForDisplay(reportSchedule);
    setReportSchedules(prevSchedules => [...prevSchedules, scheduleForDisplay]);
  };

  const submitReportSchedule = (reportSchedule: IReportSchedule) => {
    if (selectedAccount) {
      setActionInProgress(true);
      addReportSchedule(mspAccountLoggedIn, selectedAccount, reportSchedule).then(result => {
        if (result) {
          onAddReportScheduleSucceeded(reportSchedule);
        }
        setActionInProgress(false);
      });
    }
  };

  const onAddScheduleCancel = () => {
    setShowAddSchedule(false);
  };

  const onAddReportSchedule = () => {
    setShowAddSchedule(true);
  };

  const onAddScheduleSubmit = (reportSchedule: IReportSchedule) => {
    //createSampleSchedules();
    submitReportSchedule(reportSchedule);
    setShowAddSchedule(false);
  };

  const updateScheduleOnGui = (id: number, status: ReportScheduleStatus) => {
    const updatedSchedules = reportSchedules.map(schedule => {
      if (schedule.id === id) {
        return { ...schedule, status: status };
      }
      return schedule;
    });
    setReportSchedules(updatedSchedules);
  };

  const updateToggledStatusOnGui = (id: number, reportingEnabled: boolean) => {
    const updatedSchedules = reportSchedules.map(schedule => {
      if (schedule.id === id) {
        return { ...schedule, reportingEnabled: reportingEnabled };
      }
      return schedule;
    });
    setReportSchedules(updatedSchedules);
  };

  const handleRunReport = (id: number) => {
    if (selectedAccount) {
      let status = ReportScheduleStatus.Pending;
      updateScheduleOnGui(id, status);

      const reportSchedule = {
        id: id,
        status: status,
      };
      runReportSchedule(mspAccountLoggedIn, selectedAccount, reportSchedule).then(result => {
        if (result) {
          status = ReportScheduleStatus.Succeeded;
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.RunReportSchedule].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        } else {
          status = ReportScheduleStatus.Failed;
        }
        // update the UI
        updateScheduleOnGui(id, status);

        setActionInProgress(false);
      });
    }
  };

  const handleToggleReportSchedule = (id: number): void => {
    if (selectedAccount) {
      const reportSchedule = reportSchedules.find(schedule => schedule.id === id);
      if (!reportSchedule) {
        return;
      }
      const reportingEnabled = reportSchedule.reportingEnabled === true;
      // toggle it, if it's true it becomes false and viceversa
      const reportingEnabledForUpdate = !reportingEnabled;
      updateToggledStatusOnGui(id, reportingEnabledForUpdate);
      const reportScheduleForUpdate = {
        id: reportSchedule.id,
        reportingEnabled: reportingEnabledForUpdate,
      };
      updateReportSchedule(mspAccountLoggedIn, selectedAccount, reportScheduleForUpdate).then(result => {
        if (result) {
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.UpdateReportSchedule].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        } else {
          // failed, set it back to what it was before
          updateToggledStatusOnGui(id, reportingEnabled);
        }
        setActionInProgress(false);
      });
    }
  };

  const performDelete = (id: number) => {
    if (!selectedAccount) {
      return;
    }
    const scheduleToDelete = reportSchedules.find(schedule => schedule.id === id);
    if (!scheduleToDelete) {
      return;
    }

    deleteReportSchedule(mspAccountLoggedIn, selectedAccount, scheduleToDelete.id).then(result => {
      if (result) {
        dispatch(
          setSnackBarMessage({
            message: ActionMessages[ActionTypes.DeleteReportSchedule].infoMessage,
            type: ActionMessageType.Info,
          }),
        );
        // delete it from the UI
        const updatedSchedules = reportSchedules.filter(schedule => schedule.id !== id);
        setReportSchedules(updatedSchedules);
      }
      setActionInProgress(false);
    });
  };

  const handleDeleteSchedule = (id: number): void => {
    if (selectedAccount) {
      setSelectedScheduleId(id);
      setShowDeleteScheduleConfirmation(true);
    }
  };

  const handleCancelReport = (id: number): void => {
    if (!selectedAccount) {
      return;
    }
    const reportSchedule = reportSchedules.find(schedule => schedule.id === id);
    if (!reportSchedule) {
      return;
    }
    const originalStatus = reportSchedule.status ?? ReportScheduleStatus.None;
    updateScheduleOnGui(id, ReportScheduleStatus.Pending);

    const reportScheduleForCancel = {
      id: reportSchedule.id,
      status: ReportScheduleStatus.Cancelled,
    };
    cancelReportSchedule(mspAccountLoggedIn, selectedAccount, reportScheduleForCancel).then(result => {
      if (result) {
        updateScheduleOnGui(id, ReportScheduleStatus.Cancelled);
        dispatch(
          setSnackBarMessage({
            message: ActionMessages[ActionTypes.CancelReportSchedule].infoMessage,
            type: ActionMessageType.Info,
          }),
        );
      } else {
        // failed, set it back to what it was before
        updateScheduleOnGui(id, originalStatus);
      }
      setActionInProgress(false);
    });
  };

  const actions: IReportScheduleActionsProps = {
    reportingEnabled: true,
    onRunReport: handleRunReport,
    onToggleReportSchedule: handleToggleReportSchedule,
    onDeleteSchedule: handleDeleteSchedule,
    onCancelReport: handleCancelReport,
    status: ReportScheduleStatus.None,
  };

  const areSchedulesEqual = (schedule1: IReportScheduleForDisplay, schedule2: IReportScheduleForDisplay): boolean => {
    return schedule1.id === schedule2.id && schedule1.name === schedule2.name && schedule1.type === schedule2.type && schedule1.frequencyType === schedule2.frequencyType && schedule1.numberOfAccounts === schedule2.numberOfAccounts && schedule1.status === schedule2.status && schedule1.reportingEnabled === schedule2.reportingEnabled;
  };

  const populateReportSchedules = (selectedAccount: IAccount): void => {
    if (initialLoad) {
      setShowLoading(true);
    }
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchReportSchedules(selectedAccount));
      resolve(result);
    })
      .then((newReportSchedules: any) => {
        const processedReportSchedules = newReportSchedules.map((schedule: IReportSchedule) => {
          return createReportScheduleForDisplay(schedule);
        });

        setReportSchedules(prevSchedules => {
          const updatedSchedules = [...prevSchedules];
          let hasChanges = false;

          processedReportSchedules.forEach((newSchedule: IReportScheduleForDisplay) => {
            const index = updatedSchedules.findIndex(schedule => schedule.id === newSchedule.id);
            if (index !== -1) {
              if (!areSchedulesEqual(updatedSchedules[index], newSchedule)) {
                updatedSchedules[index] = newSchedule;
                hasChanges = true;
              }
            } else {
              updatedSchedules.push(newSchedule);
              hasChanges = true;
            }
          });

          if (initialLoad) {
            setShowLoading(false);
            setInitialLoad(false);
          }

          if (hasChanges) {
            return updatedSchedules;
          } else {
            return prevSchedules;
          }
        });
      })
      .catch(() => {
        dispatch(
          setSnackBarMessage({
            message: "Failed to load report schedules!",
            type: ActionMessageType.Error,
          }),
        );
      });
  };

  const onConfirmCancelDeleteSchedule = () => {
    setShowDeleteScheduleConfirmation(false);
  };

  const onConfirmDeleteSchedule = () => {
    setShowDeleteScheduleConfirmation(false);
    performDelete(selectedScheduleId);
    setSelectedScheduleId(0);
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      setShowGenerateCsvReport(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      const fetchData = async () => {
        populateReportSchedules(selectedAccount);
      };
      fetchData(); // initial fetch
      const intervalId = setInterval(fetchData, 30 * 1000); // every 30 seconds
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  return (
    <div>
      <Grid container item>
        <Grid container style={{ paddingBottom: "10px" }} spacing={1}>
          <NavigateTo to={navigateTo} text="Products & Services" callback={handleBack} />
        </Grid>
        <Grid item container xs={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader data-testid="partnerReportSchedulesPanelTitle" title="Export CSV" style={{ textAlign: "left" }} />
            <CardContent>
              <Grid container style={{ paddingBottom: "10px", marginLeft: "20px" }} spacing={1}>
                <Grid item>
                  <Button data-testid="exportUserBillingListButton" variant={"contained"} size={"large"} style={{ marginRight: "15px", backgroundColor: "white", color: "#0088CE", borderStyle: "solid", borderColor: "#0088CE", borderWidth: "1px" }} onClick={setShowExportUserBillingList}>
                    EXPORT USER BILLING LIST
                  </Button>
                </Grid>
                <Grid item>
                  <Button data-testid={"exportUsageDataButton"} variant={"contained"} size={"large"} style={{ marginRight: "15px", backgroundColor: "white", color: "#0088CE", borderStyle: "solid", borderColor: "#0088CE", borderWidth: "1px" }} onClick={setShowGenerateCsvReport}>
                    EXPORT USAGE DATA
                  </Button>
                </Grid>

                <Grid item>
                  <Button onClick={onAddReportSchedule} data-testid={"addScheduleButton"} color={"primary"} variant={"contained"} size={"large"} style={{ marginRight: "15px" }}>
                    Add Schedule
                  </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <div data-testid="scheduleListItemsTable" style={{ width: "100%", padding: "20px" }}>
                  {showLoading && initialLoad ? <CircularProgress data-testid={"loadingSchedulesList"} size="24px" /> : reportSchedules && <ReportSchedulesTable actions={actions} reportSchedules={reportSchedules} />}
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showGenerateCsvReport && selectedAccount && <ExportUsageDialog showExportUsageDialogDialog={showGenerateCsvReport} onCancel={handleCancelExportUsage} isActionInProgress={actionInProgress} onExportUsageData={handleOnExportUsage} selectedAccount={selectedAccount} emailAddress={loggedUser.email} />}
      {showExportUserBillingList && <ExportUserBillingListDialog showDialog={showExportUserBillingList} isActionInProgress={actionInProgress} dialogStep={0} title="Export User Billing List" onSubmit={onExportUserBillingListSubmit} onCancel={onExportUserBillingListCancel} selectedAccount={selectedAccount} />}
      {showAddSchedule && <AddScheduleDialog showDialog={showAddSchedule} isActionInProgress={actionInProgress} dialogStep={0} title="Add Schedule" onSubmit={onAddScheduleSubmit} onCancel={onAddScheduleCancel} />}
      {showDeleteScheduleConfirmation && selectedAccount && <DeleteScheduleDialog showDialog={showDeleteScheduleConfirmation} onCancel={onConfirmCancelDeleteSchedule} onDeleteSchedule={onConfirmDeleteSchedule} isActionInProgress={false} />}
    </div>
  );
};

export default ReportSchedules;
