import { ReportScheduleDaysOfMonthBitmask } from "./ReportSchedule/ReportScheduleDaysOfMonthBitmask";
import IReportSchedule from "./ReportSchedule/Types/IReportSchedule";
import { ReportScheduleDaysOfWeekBitmask } from "./ReportSchedule/ReportScheduleDaysOfWeekBitmask";
import { ReportScheduleFrequencyType } from "./ReportSchedule/ReportScheduleFrequencyType";
import { ReportScheduleMonthBitmask } from "./ReportSchedule/ReportScheduleMonthBitmask";

/**
 * Converts a report schedule frequency from the transport objects (API/DB) fron bitmask and enums to human-readable strings.
 * @param schedule
 * @returns
 */
export const getFrequencyForDisplay = (schedule: IReportSchedule) => {
  if (!schedule.timeOfDayHour || !schedule.timeOfDayMinute || !schedule.userTimeZone) {
    return "N/A";
  }
  const time = `${schedule.timeOfDayHour % 12 || 12}:${schedule.timeOfDayMinute.toString().padStart(2, "0")} ${schedule.timeOfDayHour >= 12 ? "PM" : "AM"}`;
  const timeZone = getTimeZoneAbbreviation(schedule.userTimeZone);

  if (schedule.frequencyType === ReportScheduleFrequencyType.Daily) {
    return `Daily, @${time} ${timeZone}`;
  } else if (schedule.frequencyType === ReportScheduleFrequencyType.Weekly) {
    if (schedule.daysOfWeek) {
      const days = getDaysFromBitmask(schedule.daysOfWeek);
      return `Weekly, ${days.join(", ")} @ ${time} ${timeZone}`;
    } else {
      return "Unknown";
    }
  } else if (schedule.frequencyType === ReportScheduleFrequencyType.Monthly) {
    let days = "";
    if (schedule.daysOfMonth) {
      days = getDaysOfMonthFromBitmask(schedule.daysOfMonth).join(", ");
    }
    return `Monthly, on the ${days} @ ${time} ${timeZone}`;
  } else if (schedule.frequencyType === ReportScheduleFrequencyType.Annually) {
    let months = "";
    if (schedule.month) {
      months = getMonthsFromBitmask(schedule.month)
        .map(month => month)
        .join(", ");
    }
    let days = "";
    if (schedule.daysOfMonth) {
      days = getDaysOfMonthFromBitmask(schedule.daysOfMonth).join(", ");
    }
    return `Yearly, in ${months} on the ${days} @ ${time} ${timeZone}`;
  } else {
    return "Unknown";
  }
};

const getTimeZoneAbbreviation = (ianaTimeZone: string): string => {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone: ianaTimeZone,
    timeZoneName: "short",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const parts = formatter.formatToParts(date);
  const timeZoneName = parts.find(part => part.type === "timeZoneName");
  return timeZoneName ? timeZoneName.value : ianaTimeZone;
};

const getDaysFromBitmask = (bitmask: number): string[] => {
  const days = [];
  const dayNames = Object.keys(ReportScheduleDaysOfWeekBitmask).filter(key => key !== "None" && isNaN(Number(key)));

  for (const day of dayNames) {
    const dayBitmask = ReportScheduleDaysOfWeekBitmask[day as keyof typeof ReportScheduleDaysOfWeekBitmask];
    if (bitmask & dayBitmask) {
      days.push(day);
    }
  }
  return days;
};

const getDaysOfMonthFromBitmask = (bitmask: number): string[] => {
  const days = [];
  const dayNames = Object.keys(ReportScheduleDaysOfMonthBitmask).filter(key => key !== "None" && isNaN(Number(key)));

  for (const day of dayNames) {
    const dayBitmask = ReportScheduleDaysOfMonthBitmask[day as keyof typeof ReportScheduleDaysOfMonthBitmask];
    if (bitmask & dayBitmask) {
      if (dayBitmask === ReportScheduleDaysOfMonthBitmask.LastDayOfMonth) {
        days.push("last day of the month");
      } else {
        const dayNumber = Math.log2(dayBitmask) + 1;
        days.push(`${dayNumber}${getOrdinalSuffix(dayNumber)}`);
      }
    }
  }
  return days;
};

const getMonthsFromBitmask = (bitmask: number): string[] => {
  const months = [];
  const monthNames = Object.keys(ReportScheduleMonthBitmask).filter(key => key !== "None" && isNaN(Number(key)));

  for (const month of monthNames) {
    const monthBitmask = ReportScheduleMonthBitmask[month as keyof typeof ReportScheduleMonthBitmask];
    if (bitmask & monthBitmask) {
      months.push(month);
    }
  }
  return months;
};

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th"; // special case for 11th-13th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
