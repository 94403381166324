import React from "react";
import { Stepper, StepLabel, Step } from "@cuda-networks/bds-core";
import { makeStyles } from "@material-ui/core";

interface IDialogStepperProps {
  stepsNames: string[];
  activeStep: number;
  useElevation?: boolean;
}

const useStyles = makeStyles(theme => ({
  // customization of the Stepper component: do not show a box shadow elevation below the component
  noElevation: {
    boxShadow: "none",
  },
}));

const DialogStepper: React.FC<IDialogStepperProps> = ({ stepsNames, activeStep, useElevation = true }) => {
  const customClasses = useStyles();

  return (
    <Stepper className={useElevation ? undefined : customClasses.noElevation} activeStep={activeStep}>
      {stepsNames.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default DialogStepper;
