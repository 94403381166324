import { ReportScheduleFrequencyType } from "../ReportScheduleFrequencyType";
import { ReportScheduleStatus } from "../ReportScheduleStatus";
import { ReportScheduleType } from "../ReportScheduleType";
import IReportSchedule from "./IReportSchedule";

export abstract class ReportScheduleBase implements IReportSchedule {
  id?: number;
  name: string;
  type: ReportScheduleType;
  abstract frequencyType: ReportScheduleFrequencyType;
  timeOfDayHour: number;
  timeOfDayMinute: number;
  includedBccAccountIds?: number[];
  excludedBccAccountIds?: number[];
  numberOfAccounts?: number;
  userTimeZone: string;
  status: ReportScheduleStatus;

  /**
   *
   * @param name Name of the report
   * @param type Type of the Report - e.g. User Billing List
   * @param timeOfDayHour - hour when the report should run, between 0 and 23
   * @param timeOfDayMinute  - minute when the report should run, between 0 and 59
   * @param userTimeZone - Iana time zone string, e.g. America/New_York
   * @param includedBccAccountIds - array of integers representing BCC Account IDs of SMBs to include in the report. Specify an array containing the value 0 for "All"
   * @param excludedBccAccountIds - when includedBccAccountIds contains the array value [0], allows excluding SMB bcc account ids
   * @param id  ID of the report (from the database - useful for update operations)
   * @param numberOfAccounts - number of SMB accounts associated with the report
   * @param status - status of the report schedule
   */
  constructor(name: string, type: ReportScheduleType, timeOfDayHour: number, timeOfDayMinute: number, userTimeZone: string, includedBccAccountIds?: number[], excludedBccAccountIds?: number[], id?: number, numberOfAccounts?: number, status: ReportScheduleStatus = ReportScheduleStatus.None) {
    this.validateTimeOfDayHour(timeOfDayHour);
    this.validateTimeOfDayMinute(timeOfDayMinute);
    this.validateUserTimeZone(userTimeZone);
    if (includedBccAccountIds) {
      this.validateIncludedBccAccountIds(includedBccAccountIds);
      if (excludedBccAccountIds) {
        this.validateExcludedBccAccountIds(includedBccAccountIds, excludedBccAccountIds);
      }
    }

    this.name = name;
    this.type = type;
    this.timeOfDayHour = timeOfDayHour;
    this.timeOfDayMinute = timeOfDayMinute;
    this.userTimeZone = userTimeZone;
    this.includedBccAccountIds = includedBccAccountIds;
    this.excludedBccAccountIds = excludedBccAccountIds;
    this.id = id;
    this.numberOfAccounts = numberOfAccounts;
    this.status = status;
  }

  private validateTimeOfDayHour(hour: number): void {
    if (!Number.isInteger(hour) || hour < 0 || hour > 23) {
      throw new Error("timeOfDayHour must be an integer between 0 and 23.");
    }
  }

  private validateTimeOfDayMinute(minute: number): void {
    if (!Number.isInteger(minute) || minute < 0 || minute > 59) {
      throw new Error("timeOfDayMinute must be an integer between 0 and 59.");
    }
  }

  private validateUserTimeZone(timeZone: string): void {
    try {
      Intl.DateTimeFormat(undefined, { timeZone });
    } catch (e) {
      throw new Error("userTimeZone must be a valid IANA time zone string.");
    }
  }
  private validateIncludedBccAccountIds(includedBccAccountIds: number[]): void {
    if (includedBccAccountIds.includes(0) && includedBccAccountIds.length > 1) {
      throw new Error("If includedBccAccountIds contains 0 (which means include ALL), no other elements are allowed.");
    }
  }

  private validateExcludedBccAccountIds(includedBccAccountIds: number[], excludedBccAccountIds: number[]): void {
    if (!includedBccAccountIds.includes(0) && excludedBccAccountIds.length > 0) {
      throw new Error("If includedBccAccountIds does not contain 0 (which means ALL), excludedBccAccountIds must be empty, since exclusions are only possible when using ALL.");
    }
  }
}
