import { DialogTitle, DialogActions, Button, DialogContent, Typography, Dialog } from "@cuda-networks/bds-core";
import React from "react";
import { handleBackdropClick } from "../../../utility";

interface IDeleteScheduleProps {
  onCancel: () => void;
  onDeleteSchedule: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
}

const DeleteScheduleDialog: React.FC<IDeleteScheduleProps> = ({ showDialog, onCancel, onDeleteSchedule, isActionInProgress }) => {
  return (
    <Dialog className={"DeleteScheduleDialog"} data-testid="deleteScheduleDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false} style={{ zIndex: 4002 }}>
      <div style={{ width: "400px" }}>
        <DialogTitle data-testid="deleteScheduleDialogTitle" id="alert-dialog-title">
          Delete Schedule
        </DialogTitle>
        <DialogContent>
          <Typography data-testid="deleteScheduleMessage" variant="body1">
            Are you sure you want to delete this schedule?
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button data-testid="deleteScheduleCancelButton" variant="text" disabled={isActionInProgress} type={"submit"} onClick={onCancel}>
            CANCEL
          </Button>
          <Button data-testid="deleteScheduleConfirmButton" type={"submit"} isLoading={isActionInProgress} disabled={isActionInProgress} onClick={onDeleteSchedule}>
            CONFIRM
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DeleteScheduleDialog;
