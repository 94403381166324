import React, { useEffect } from "react";
import StartApp from "./components/StartApp";
import { ActionFunction, createBrowserRouter, RouterProvider } from "react-router-dom";
import NewUser from "./components/NewUserPage/NewUser";
import { BDSProvider } from "@cuda-networks/bds-core";
import { OptionsObject, SnackbarProvider, closeSnackbar, enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackBarMessage } from "./actions/generalActions";
import { IAppState } from "./store/store";
import ActionLink from "./components/SnackBar/ActionLink";
import CloseButton from "./components/SnackBar/CloseButton";
import LandingPage from "./components/LandingPage";
import ServiceUnavailable from "./components/ServiceUnavailable";
import { PageNotFound } from "./components/PageNotFound";

const myAction: ActionFunction<void> = params => {
  console.debug(params);
  console.debug("Route : " + params.request.url);
  return 0;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartApp />,
  },
  {
    path: "/filters?",
    element: <StartApp />,
  },
  {
    path: "/accounts/:accountId",
    element: <StartApp />,
    loader: myAction,
  },
  {
    path: "/accounts/:accountId/:tabId",
    element: <StartApp />,
    loader: myAction,
  },
  {
    path: "/accounts/:accountId/:tabId/partner-resources",
    element: <StartApp partnerResources={true} />,
    loader: myAction,
  },
  {
    path: "/accounts/:accountId/:tabId/report-schedules",
    element: <StartApp reportSchedules={true} />,
    loader: myAction,
  },
  {
    path: "/accounts/:accountId/:tabId/user-billing",
    element: <StartApp userBilling={true} />,
    loader: myAction,
  },
  {
    path: "/accounts/:accountId/integrations",
    element: <StartApp integration={true} />,
  },
  {
    path: "/accounts/:accountId/integrations/:integrationId/:tabId",
    action: myAction,
    element: <StartApp integration={true} />,
    loader: myAction,
  },
  {
    path: "/new-user",
    element: <NewUser />,
  },
  {
    path: "/welcome",
    element: <LandingPage />,
  },
  {
    path: "/services-unavailable",
    element: <ServiceUnavailable />,
  },
  {
    path: "*", // This will catch all routes
    element: <PageNotFound />,
  },
]);

const App = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const snackBarMessage = useSelector((state: IAppState) => state.generalState.snackBarMessage);
  console.debug(`React Version:${React.version}`);

  useEffect(() => {
    if (snackBarMessage.message !== "") {
      const snackBarOptions: OptionsObject = {
        variant: snackBarMessage.type,
        autoHideDuration: 5000,
        onClose: () => {
          if (snackBarMessage.closeAction !== undefined) {
            snackBarMessage.closeAction();
          }
          dispatch(removeSnackBarMessage());
        },
        action: [
          snackBarMessage.action !== undefined ? (
            <ActionLink
              onActionLinkClick={() => {
                if (snackBarMessage.action !== undefined) {
                  snackBarMessage.action();
                  closeSnackbar(key);
                  dispatch(removeSnackBarMessage());
                }
              }}
            />
          ) : null,
          <CloseButton
            onCloseSnackbar={() => {
              if (snackBarMessage.closeAction !== undefined) {
                snackBarMessage.closeAction();
              }
              closeSnackbar(key);
              dispatch(removeSnackBarMessage());
            }}
          />,
        ],
      };
      const key = enqueueSnackbar(snackBarMessage.message, snackBarOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackBarMessage]);

  return (
    <BDSProvider>
      <SnackbarProvider maxSnack={10}>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </BDSProvider>
  );
};

export default App;
