import { Grid, RadioButton, Typography } from "@cuda-networks/bds-core";
import React, { ChangeEvent } from "react";
import DropDown from "../../../DropDown";
import { Frequencies } from "../AddScheduleDialog";
import { ReportScheduleType } from "../../../../models/ReportSchedule/ReportScheduleType";

interface ITypeAndFrequencyStepProps {
  reportType: ReportScheduleType;
  onReportTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFrequencyId: number;
  onFrequencyChange: (id: number) => void;
  timeValue: string;
  onTimeValueChanged: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TypeAndFrequencyStep: React.FC<ITypeAndFrequencyStepProps> = ({ reportType, onReportTypeChange, selectedFrequencyId, onFrequencyChange, timeValue, onTimeValueChanged }) => {
  function getDateTimeInputs(selectedFrequencyId: number) {
    switch (selectedFrequencyId) {
      case 1:
        return (
          <div style={{ marginLeft: 10 }} data-testid="dailyFrequencyTimeOfDayPicker">
            <Grid item style={{ minWidth: 150 }}>
              <Typography style={{ marginBottom: 10 }}>Time of Day</Typography>
              <input type="time" value={timeValue} onChange={onTimeValueChanged} style={{ height: "35px" }} />
            </Grid>
          </div>
        );
      case 2:
        return <div></div>;
      case 3:
        return <div></div>;
      case 4:
        return <div></div>;
      default:
        break;
    }
  }

  return (
    <div data-testid="addEditScheduleTypeAndFrequencyStep">
      <Grid item container spacing={3}>
        <Grid container item xs={12}>
          <Grid style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 0px 0px", marginBottom: "7px" }} item xs={12} container direction="column">
            <Typography style={{ fontWeight: "bold" }}>Type</Typography>
            <Grid item container>
              <Grid item>
                <RadioButton data-testid="usageDataRadioBtn" label="Usage Data" value={ReportScheduleType.UsageData} checked={reportType === ReportScheduleType.UsageData} onChange={onReportTypeChange} />
              </Grid>
              <Grid item>
                <RadioButton data-testid="userBillingListRadioBtn" label="User Billing List" value={ReportScheduleType.UserBillingList} checked={reportType === ReportScheduleType.UserBillingList} onChange={onReportTypeChange} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction="column">
            <Grid item container>
              <Grid item style={{ minWidth: 150 }}>
                <div data-testid="addScheduleFrequencyDropdown">
                  <Typography style={{ marginBottom: 10 }}>Frequency</Typography>
                  <DropDown size="medium" options={Frequencies.map(frequency => frequency.value)} selectedOptionId={selectedFrequencyId} error={""} handleChange={onFrequencyChange} disabled={false} label={""} />
                </div>
              </Grid>
              <Grid item>{getDateTimeInputs(selectedFrequencyId)}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TypeAndFrequencyStep;
