import React, { useState, useEffect } from "react";
import { DataTable, Pager, DataTableColumn as Column } from "@cuda-networks/bds-core";
import { process } from "@progress/kendo-data-query";
import ReportScheduleActions, { IReportScheduleActionsProps } from "./ReportScheduleActions";
import { Typography, useMediaQuery } from "@material-ui/core";
import { PAGE_SIZES } from "../../../models/TableConfig";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { getButtonCount } from "../../../utility";
import IReportScheduleForDisplay from "../../../models/ReportSchedule/IReportScheduleForDisplay";

interface IReportSchedulesTableProps {
  reportSchedules: IReportScheduleForDisplay[];
  actions: IReportScheduleActionsProps;
}

const ReportSchedulesTable: React.FC<IReportSchedulesTableProps> = ({ reportSchedules, actions }) => {
  const tableState = useSelector((state: IAppState) => state.accountState.reportSchedulesTableState);
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "Schedule Name",
        field: "name",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
      {
        title: "Type",
        field: "type",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
      {
        title: "Frequency",
        field: "frequency",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: false,
      },
      {
        title: "Accounts",
        field: "numberOfAccounts",
        show: true,
        filter: "numeric",
        filtrable: false,
        sortable: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(reportSchedules, dataState as any),
  });

  useEffect(() => {
    setGridState({
      dataState,
      dataResult: process(reportSchedules, dataState as any),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSchedules]);

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(reportSchedules, e.dataState),
    });
  };

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const ReportScheduleActionsCell = (props: any) => <ReportScheduleActions status={props.dataItem.status} reportingEnabled={props.dataItem.reportingEnabled} onRunReport={() => actions.onRunReport(props.dataItem.id)} onToggleReportSchedule={() => actions.onToggleReportSchedule(props.dataItem.id)} onCancelReport={() => actions.onCancelReport(props.dataItem.id)} onDeleteSchedule={() => actions.onDeleteSchedule(props.dataItem.id)} />;

  interface ReportScheduleCellProps {
    value: string | number;
    reportingEnabled: boolean;
  }
  const getCellStyle = (reportingEnabled: boolean) => ({
    color: reportingEnabled ? "inherit" : "#a0a0a0",
    backgroundColor: reportingEnabled ? "inherit" : "#f0f0f0",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontWeight: "inherit",
  });
  const ReportScheduleNameCell: React.FC<ReportScheduleCellProps> = ({ value, reportingEnabled }) => (
    <td style={getCellStyle(reportingEnabled)}>
      <Typography style={{ color: reportingEnabled ? "#0088CE" : "#8EC9D9" }}>{value}</Typography>
    </td>
  );

  const ReportScheduleCell: React.FC<ReportScheduleCellProps> = ({ value, reportingEnabled }) => {
    return (
      <td style={getCellStyle(reportingEnabled)}>
        <Typography>{value}</Typography>
      </td>
    );
  };

  return (
    <DataTable
      className={"noScrollbar noBorders ReportSchedulesTable"}
      pageConfig={{
        pageable: {
          pageSizes: PAGE_SIZES,
          buttonCount: buttonCount,
        },
        skip: tableState.skip,
        take: tableState.take,
        total: gridState.dataResult.total,
      }}
      sortConfig={{
        sortable: true,
        sort: tableState.sort,
      }}
      data={gridState.dataResult}
      filter={gridState.dataState.filter}
      pager={gridState.dataResult.data.length > 0 && Pager}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      {...(gridState.dataState as any)}
    >
      <Column key={"reportScheduleName"} title={"Schedule Name"} field={"name"} cell={(props: any) => <ReportScheduleNameCell value={props.dataItem.name} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleType"} title={"Type"} field={"type"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.type} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleFrequency"} title={"Frequency"} field={"frequencyType"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.frequencyType} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleNumberOfAccounts"} title={"Accounts"} field={"numberOfAccounts"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.numberOfAccounts} reportingEnabled={props.dataItem.reportingEnabled} />} width={150} />
      <Column key={"reportScheduleActions"} title={"Actions"} cell={ReportScheduleActionsCell} width={250} />
    </DataTable>
  );
};

export default ReportSchedulesTable;
