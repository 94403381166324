import { Dialog, DialogActions, DialogContent, Button, Grid, DialogTitle, TextField } from "@cuda-networks/bds-core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { handleBackdropClick } from "../../../utility";
import DialogStepper from "../../DialogStepper";
import TypeAndFrequencyStep from "./DialogSteps/TypeAndFrequencyStep";
import { ReportScheduleType } from "../../../models/ReportSchedule/ReportScheduleType";
import { ReportScheduleBase } from "../../../models/ReportSchedule/Types/ReportScheduleBase";
import DailyReportSchedule from "../../../models/ReportSchedule/Types/DailyReportSchedule";

export const TypeAndFrequencyStepLabel = "Type & Frequency";
export const AccountsStepLabel = "Accounts";
export const SummaryStepLabel = "Summary";

export const ReportTypes = ["Usage Data", "User Billing List"];
export const Frequencies = [
  { id: 1, value: "Daily" },
  { id: 2, value: "Weekly" },
  { id: 3, value: "Monthly" },
  { id: 4, value: "Annually" },
];

interface IAddScheduleDialogProps {
  onSubmit: (reportSchedule: ReportScheduleBase) => void;
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  dialogStep: number;
  title: string;
}

const AddScheduleDialog: React.FC<IAddScheduleDialogProps> = ({ showDialog, dialogStep, onCancel, onSubmit, isActionInProgress, title }) => {
  const [activeStep, setActiveStep] = useState(dialogStep);
  const [nextButtonLabel, setNextButtonLabel] = useState("NEXT");
  const [dialogStepsNames, setDialogStepsNames] = useState([""]);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);
  const [reportType, setReportType] = useState<ReportScheduleType>(ReportScheduleType.UsageData);
  const [selectedFrequencyId, setSelectedFrequencyId] = useState(1);
  const [scheduleName, setScheduleName] = useState("");
  const [timeValue, setTimeValue] = useState("00:00");

  useEffect(() => {
    setDialogStepsNames(getAddScheduleStepsNames(reportType));
  }, [reportType]);

  function onReportTypeChange(event: ChangeEvent<HTMLInputElement>): void {
    setReportType(Number(event.target.value));
  }

  function onFrequencyChange(id: number): void {
    setSelectedFrequencyId(id);
  }

  function onTimeValueChanged(event: ChangeEvent<HTMLInputElement>): void {
    setTimeValue(event.target.value);
  }

  function getStepContent(step: number) {
    const stepName = dialogStepsNames[step];
    switch (stepName) {
      case TypeAndFrequencyStepLabel:
        return <TypeAndFrequencyStep reportType={reportType} onReportTypeChange={onReportTypeChange} selectedFrequencyId={selectedFrequencyId} onFrequencyChange={onFrequencyChange} timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} />;
      case AccountsStepLabel:
        return <div></div>;
      case SummaryStepLabel:
        return <div></div>;
      default:
        break;
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCancel = () => {
    onCancel();
  };

  const createReportSchedule = () => {
    const timeValueArray = timeValue.split(":");
    const timeValueHour = Number(timeValueArray[0]);
    const timeValueMinutes = Number(timeValueArray[1]);
    const schedule = new DailyReportSchedule(scheduleName, reportType, timeValueHour, timeValueMinutes, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return schedule;
  };

  const handleNext = () => {
    if (activeStep === dialogStepsNames.length - 1) {
      onSubmit(createReportSchedule());
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const onNameChanged = (newValue: string) => {
    setScheduleName(newValue);
  };

  useEffect(() => {
    const { isBackVisible, nextButtonLabel } = getButtonStates(activeStep, dialogStepsNames.length);
    setIsBackButtonVisible(isBackVisible);
    setNextButtonLabel(nextButtonLabel);
  }, [activeStep, dialogStepsNames]);

  return (
    <Dialog className="addScheduleDialog" disableEscapeKeyDown={isActionInProgress} data-testid="addScheduleDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
      <div className="addSchedule">
        <DialogTitle data-testid="addScheduleDialogTitle" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <div>
          <TextField style={{ width: 560, margin: 20 }} data-testid="addEditScheduleName" placeholder="New Schedule" value={scheduleName} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onNameChanged(ev.target.value)} />
        </div>
        <DialogStepper useElevation={false} stepsNames={dialogStepsNames} activeStep={activeStep} />
        <DialogContent>
          <div className="DialogContentDiv" style={{ minHeight: 490 }}>
            <Grid item xs={12}>
              {getStepContent(activeStep)}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button data-testid="cancelAddScheduleBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleCancel}>
              CANCEL
            </Button>
            {isBackButtonVisible && (
              <Button data-testid="backAddScheduleBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleBack}>
                BACK
              </Button>
            )}
            <Button data-testid="confirmNextAddScheduleBtn" disabled={false} loadingPosition="start" size="large" type={"submit"} color="primary" isLoading={isActionInProgress} onClick={() => handleNext()}>
              {nextButtonLabel}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddScheduleDialog;

export function getAddScheduleStepsNames(reportType: ReportScheduleType): string[] {
  let steps: string[] = [];
  switch (reportType) {
    case ReportScheduleType.UsageData: {
      steps = [TypeAndFrequencyStepLabel, SummaryStepLabel];
      break;
    }
    case ReportScheduleType.UserBillingList: {
      steps = [TypeAndFrequencyStepLabel, AccountsStepLabel, SummaryStepLabel];
      break;
    }
    default:
      steps = [];
  }

  return steps;
}

export function getButtonStates(activeStep: number, dialogStepsLength: number) {
  let isBackVisible = true;
  let nextButtonLabel = "ADD";

  if (activeStep !== dialogStepsLength - 1) {
    nextButtonLabel = "NEXT";
  }

  if (activeStep === 0) {
    isBackVisible = false;
  }

  return { isBackVisible, nextButtonLabel };
}
