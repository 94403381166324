import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@cuda-networks/bds-core";
import { IAppState } from "../store/store";
import { getApiUrlAction, generateCustomAccessTokenAction, hasUsersAction, loadAction, loadLoggedInMsp, preFetch, setError, setLoggedUser, setSnackBarMessage } from "../actions/generalActions";
import MspUILoadingAppProgress from "./MspUILoadingAppProgress";
import ErrorMessage from "./ErrorMessage";
import env from "@beam-australia/react-env";
import MspApp from "./MspApp";
import ErrorAfterLogin from "./ErrorAfterLogin";
import { TokenStorage } from "../TokenStorage";
import { isStringNullOrEmpty } from "../utility";
import CustomerMessagesType from "../models/CustomerMessagesType";
import { Navigate, useNavigate } from "react-router-dom";
import { setSelectedAccountAction } from "../actions/accountActions";
import MspType from "../models/MspType";
import { validatePath } from "../Utilities/pathValidator";
import { PageNotFound } from "./PageNotFound";
import ActionMessageType from "../models/ActionMessageType";
import { validateFilters } from "../Utilities/filtersValidator";
import { getAccountIdFromUrl } from "../Utilities/UrlParser";

const StartApp = (props: any): React.JSX.Element => {
  const dispatch = useDispatch();
  const isMspLoggedIn = useSelector((state: IAppState) => state.generalState.isMspLoggedIn);
  const loading = useSelector((state: IAppState) => state.generalState.loading);
  const errorMessage = useSelector((state: IAppState) => state.generalState.errorMessage);
  const apiError = useSelector((state: IAppState) => state.generalState.apiError);
  const noOfLoadedSubpartners = useSelector((state: IAppState) => state.generalState.noOfLoadedSubpartners);
  const isDefaultUser = useSelector((state: IAppState) => state.generalState.isDefaultUser);
  const hasUsers = useSelector((state: IAppState) => state.generalState.hasUsers);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const showCustomerMessage = useSelector((state: IAppState) => state.generalState.showCustomerMessage);
  const customerMessageType = useSelector((state: IAppState) => state.generalState.customerMessageType);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const { isBaLoggedIn } = useSelector((state: IAppState) => state.generalState);
  const { mspAccounts, accountsNames } = useSelector((state: IAppState) => state.accountState);
  const location = window.location;
  const accountId = getAccountIdFromUrl();
  const navigate = useNavigate();

  useEffect(() => {
    if (mspAccountLoggedIn.type === MspType.Customer) {
      dispatch(setSelectedAccountAction(mspAccountLoggedIn));
    } else {
      if (location.pathname.localeCompare("/") === 0) {
        dispatch(setSelectedAccountAction(undefined));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mspAccountLoggedIn]);

  useEffect(() => {
    if (mspAccountLoggedIn.id > 0 && isDefaultUser) {
      dispatch(hasUsersAction(mspAccountLoggedIn));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn, isDefaultUser]);

  const getEchoV3AccessToken = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(generateCustomAccessTokenAction());
      resolve(result);
    });

  const getUrl = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getApiUrlAction(env("URLS")));
      resolve(result);
    });

  const getLoggedUser = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(setLoggedUser());
      resolve(result);
    });

  useEffect(() => {
    dispatch(loadAction(true));
    const accessToken = TokenStorage.getBccAccessToken();
    const idToken = TokenStorage.getBccIdToken();
    if (accessToken && idToken) {
      getUrl().then(success => {
        if (success) {
          const echoV3AccessToken = TokenStorage.getEchoV3AccessToken();
          if (echoV3AccessToken && echoV3AccessToken !== null && !isStringNullOrEmpty(echoV3AccessToken)) {
            getLoggedUser().then(isDefaultUser => {
              preFetch();
              dispatch(loadLoggedInMsp(isDefaultUser));
            });
          } else {
            getEchoV3AccessToken().then(response => {
              if (response) {
                getLoggedUser().then(isDefaultUser => {
                  preFetch();
                  dispatch(loadLoggedInMsp(isDefaultUser));
                });
              } else {
                dispatch(setError("No access token, you need to login agan"));
              }
            });
          }
        }
      });
    } else if (window.location.hostname === "localhost") {
      //when working on localhost make things easier for dev and do not requier bcc_access_token and bcc_id_token, but directly with a v3 access_token provided in session storage
      const echoV3AccessToken = TokenStorage.getEchoV3AccessToken();
      if (echoV3AccessToken) {
        getUrl().then(success => {
          if (success) {
            getLoggedUser().then(isDefaultUser => {
              preFetch();
              dispatch(loadLoggedInMsp(isDefaultUser));
            });
          }
        });
      }
    } else {
      dispatch(setError("No bcc access token, you need to login again"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appIsLoading = useCallback(() => {
    let isLoading = true;
    if (isMspLoggedIn && isDefaultUser !== undefined) {
      isLoading = false;
      if (isDefaultUser) {
        isLoading = true;
        if (hasUsers !== undefined) {
          isLoading = false;
        }
      }
    }
    return isLoading;
  }, [hasUsers, isDefaultUser, isMspLoggedIn]);

  const renderLoginByToken = useMemo(() => {
    let content;
    if (!appIsLoading()) {
      if (isDefaultUser) {
        content = <Navigate to="/new-user" />;
      } else {
        if (validatePath(accountId, location, mspAccountLoggedIn, mspAccounts, isBaLoggedIn, accountsNames)) {
          if (validateFilters(accountId, location.search, mspAccountLoggedIn, accountsNames, accountsOrders)) {
            content = <MspApp integration={props.integration} partnerResources={props.partnerResources} reportSchedules={props.reportSchedules} userBilling={props.userBilling} />;
          } else {
            navigate("/");
            dispatch(setSelectedAccountAction(undefined));
            dispatch(setSnackBarMessage({ message: "You have been redirected to homepage and your applied filters were reset.", type: ActionMessageType.Warning }));
            content = <MspApp integration={props.integration} partnerResources={props.partnerResources} reportSchedules={props.reportSchedules} userBilling={props.userBilling} />;
          }
        } else {
          document.title = `Page Not Found - MSP App`;
          return <PageNotFound />;
        }
      }
    } else {
      if (showCustomerMessage) {
        switch (customerMessageType) {
          case CustomerMessagesType.Marketing:
            content = <Navigate to="/welcome" />;
            break;
          case CustomerMessagesType.TemporaryUnreachable:
            content = <Navigate to="/services-unavailable" />;
            break;
        }
      } else if (errorMessage === undefined || errorMessage === "") {
        if (loading) {
          content = <MspUILoadingAppProgress noOfLoadedSubpartners={noOfLoadedSubpartners} noOfSubpartners={0} />;
        } else {
          content = <Typography>Waiting for server...</Typography>;
        }
      }
    }

    return (
      <div className="App" style={{ paddingTop: 50, paddingBottom: 0, paddingRight: 50, paddingLeft: 50 }}>
        {content}
      </div>
    );
  }, [accountId, accountsNames, accountsOrders, appIsLoading, customerMessageType, dispatch, errorMessage, isBaLoggedIn, isDefaultUser, loading, location, mspAccountLoggedIn, mspAccounts, navigate, noOfLoadedSubpartners, props.integration, props.partnerResources, props.reportSchedules, props.userBilling, showCustomerMessage]);

  const onCloseMessage = () => {
    dispatch(setError(""));
  };

  return (
    <div>
      {apiError !== undefined && <ErrorAfterLogin apiError={apiError} />}
      {errorMessage && errorMessage.length > 0 && <ErrorMessage message={errorMessage} onCloseMessage={onCloseMessage} />}
      {renderLoginByToken}
    </div>
  );
};

export default StartApp;
