import { Switch, SwitchLabel, Grid, IconButton } from "@cuda-networks/bds-core";
import { CheckCircle, Delete, ErrorOutline, PlayArrow, StopSharp, HourglassFullOutlined } from "@material-ui/icons";
import React from "react";
import { ReportScheduleStatus } from "../../../models/ReportSchedule/ReportScheduleStatus";
import { Tooltip } from "@material-ui/core";

export interface IReportScheduleActionsProps {
  onRunReport: (id: number) => void;
  onToggleReportSchedule: (id: number) => void;
  onDeleteSchedule: (id: number) => void;
  onCancelReport: (id: number) => void;
  reportingEnabled: boolean;
  status: ReportScheduleStatus;
}

const ReportScheduleActions: React.FC<IReportScheduleActionsProps> = ({ reportingEnabled, status, onRunReport, onToggleReportSchedule, onDeleteSchedule, onCancelReport }) => {
  const cellStyle: React.CSSProperties = {
    color: reportingEnabled ? "#0088CE" : "#a0a0a0",
    backgroundColor: reportingEnabled ? "inherit" : "#f0f0f0",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontWeight: "inherit",
    display: "flex",
    alignItems: "center",
    position: "relative",
  };
  const getStatusIcon = (status: ReportScheduleStatus) => {
    const renderStatusIndicator = (tooltip: string, IconComponent: React.ElementType, color: string) => (
      <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
        <Tooltip title={tooltip}>
          <IconComponent
            style={{
              position: "absolute",
              left: 30,
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: 16,
              color: color,
            }}
          />
        </Tooltip>
      </div>
    );

    switch (status) {
      case ReportScheduleStatus.Succeeded:
        return renderStatusIndicator("Last run succeeded", CheckCircle, "#4caf50");
      case ReportScheduleStatus.Cancelled:
        return renderStatusIndicator("Last run was cancelled", ErrorOutline, "#ffeb3b");
      case ReportScheduleStatus.Failed:
        return renderStatusIndicator("Last run has failed", ErrorOutline, "#f44336");
      default:
        return null;
    }
  };
  const getRunReportStatusIcon = (status: ReportScheduleStatus) => {
    switch (status) {
      case ReportScheduleStatus.Running:
        return <StopSharp />;
      case ReportScheduleStatus.Pending:
        return <HourglassFullOutlined />;
      case ReportScheduleStatus.Succeeded:
      case ReportScheduleStatus.Cancelled:
      case ReportScheduleStatus.Failed:
        return <PlayArrow />;
      default:
        return <PlayArrow />;
    }
  };

  const canCancelReport = (status: ReportScheduleStatus): boolean => {
    return status === ReportScheduleStatus.Pending || status === ReportScheduleStatus.Running;
  };

  return (
    <td style={cellStyle}>
      <Grid container item>
        <Tooltip title={reportingEnabled ? "Disable" : "Enable"}>
          <Grid item>
            <SwitchLabel label={""} control={<Switch data-testid="toggleReportSchedule" checked={reportingEnabled} onChange={onToggleReportSchedule} />} style={{ marginTop: "5px" }} />
          </Grid>
        </Tooltip>
        <Grid item style={{ display: "flex", alignItems: "center", position: "relative", marginRight: 10 }}>
          <Tooltip title={canCancelReport(status) ? "Cancel" : "Run"}>
            <IconButton style={{ color: "#0088CE" }} onClick={canCancelReport(status) ? onCancelReport : onRunReport}>
              {getRunReportStatusIcon(status)}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <IconButton onClick={onDeleteSchedule}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          {getStatusIcon(status)}
        </Grid>
      </Grid>
    </td>
  );
};

export default ReportScheduleActions;
