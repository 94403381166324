import { ReportScheduleFrequencyType } from "../ReportScheduleFrequencyType";
import { ReportScheduleStatus } from "../ReportScheduleStatus";
import { ReportScheduleType } from "../ReportScheduleType";
import { ReportScheduleBase } from "./ReportScheduleBase";

class DailyReportSchedule extends ReportScheduleBase {
  public frequencyType: ReportScheduleFrequencyType = ReportScheduleFrequencyType.Daily;

  constructor(name: string, type: ReportScheduleType, timeOfDayHour: number, timeOfDayMinute: number, userTimeZone: string, includedBccAccountIds: number[] = [], excludedBccAccountIds: number[] = [], id?: number, numberOfAccounts?: number, status: ReportScheduleStatus = ReportScheduleStatus.None) {
    super(name, type, timeOfDayHour, timeOfDayMinute, userTimeZone, includedBccAccountIds, excludedBccAccountIds, id, numberOfAccounts, status);
  }
}
export default DailyReportSchedule;
